<template>
  <div>
    <Navbar />
    <TermsAndConditions />
    <Footer />
  </div>
</template>

<script>
import TermsAndConditions from "../components/information/TermsAndConditions.vue";
import Footer from "../components/shared/Footer.vue";
import Navbar from "../components/shared/Navbar.vue";

export default {
  components: {
    TermsAndConditions,
    Navbar,
    Footer,
  },
  created() {
    const lang = this.$i18n.locale === "tr" ? "" : this.$i18n.locale;
    location.href = `https://flinktax.de/${lang}/agb`;
  },
};
</script>

<style></style>
